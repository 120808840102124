import { AdditionalActionCall, SettingsFormData, FormInputType } from '../../components';

export const rebootFormDefinition: SettingsFormData[] = [
  {
    elements: [
      {
        key: AdditionalActionCall.REBOOT,
        type: FormInputType.BUTTON_PRIMARY,
        title: 'kw.settings.reboot.title',
        additionalActionCallType: AdditionalActionCall.REBOOT,
        accessibilityId: 'btn--reboot',
      },
    ],
  },
];
