import { KWLogger } from './KWLogger';

export abstract class StorageUtils {
  public static set<T>(key: string, value?: T, storage: Storage = window.localStorage): void {
    if (value === undefined) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, JSON.stringify(value));
    }
  }

  public static setSession<T>(key: string, value?: T, storage: Storage = window.sessionStorage): void {
    if (value === undefined) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, JSON.stringify(value));
    }
  }

  public static deleteSession<T>(key: string, storage: Storage = window.sessionStorage): void {
    storage.removeItem(key);
  }

  public static get<T>(key: string, storage: Storage = window.localStorage): T | undefined {
    const value = storage.getItem(key);
    if (value != null) {
      try {
        return JSON.parse(value) as T;
      } catch (e) {
        KWLogger.warn(`Loading storage value failed for key: ${key}`);
        return undefined;
      }
    }
    return undefined;
  }

  public static getSession<T>(key: string, storage: Storage = window.sessionStorage): T | undefined {
    const value = storage.getItem(key);
    if (value != null) {
      try {
        return JSON.parse(value) as T;
      } catch (e) {
        KWLogger.warn(`Loading storage value failed for key: ${key}`);
        return undefined;
      }
    }
    return undefined;
  }

  public static addToStoredList(key: string, item: string, storage: Storage = window.localStorage): void {
    const list = this.get<string[]>(key, storage) || [];
    if (!list.includes(item)) {
      list.push(item);
      this.set(key, list, storage);
    }
  }

  public static removeFromStoredList(key: string, item: string, storage: Storage = window.localStorage): void {
    const list = this.get<string[]>(key, storage) || [];
    const index = list.indexOf(item);
    if (index !== -1) {
      list.splice(index, 1);
      this.set(key, list, storage);
    }
  }
}
