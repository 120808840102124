import {
  DiagnosticsKey,
  DisplayTextKey,
  ExtMeterKey,
  LmgmtKey,
  NetworkKey,
  RfidKey,
} from '@keba-wallbox-plugin/plugin';
import { MaxPhases, PhaseUsed, UnitConverter, WallboxPVStatus } from '@kw-shared/common';
import { addSeconds, subHours } from 'date-fns';
import { Observable, map, of } from 'rxjs';
import {
  RemoteWallbox,
  RemoteWallboxConnectionStatus,
  RemoteWallboxState,
  RemoteWallboxUpdateState,
} from '../../../../models';
import { Filter } from '../../types/Filter';
import { FakeRepository } from '../base/FakeRepository';
import { IWallboxRepository } from './IWallboxRepository';
import { RemoteWallboxFirmwareUpdateInfoDTO } from './dto/RemoteWallboxFirmwareUpdateInfoDTO';
import {
  RemoteWallboxSettingsChargepointAvailabilityDTO,
  RemoteWallboxSettingsConfiguration,
  RemoteWallboxSettingsDTO,
} from './dto/RemoteWallboxSettingsDTO';

const fakeWallboxPowerSettings: RemoteWallboxSettingsConfiguration = {
  max_available_current: '25114',
  nominal_voltage: '230',
  connector_phase_rotation: 'L1_L2_L3',
  min_default_current: '6000',
  max_current: '6000',
};

export class FakeWallboxRepository extends FakeRepository<RemoteWallbox> implements IWallboxRepository {
  constructor() {
    super([
      {
        name: 'My favorite',
        state: RemoteWallboxState.CHARGING,
        serialNumber: '123',
        model: 'KC-P30-......C.-.',
        id: 'w-123',
        firmwareVersion: '1.15.0',
        requiredFirmwareVersion: '1.15.0',
        updateIndication: RemoteWallboxUpdateState.CRITICAL_UPDATE,
        pvStatus: {
          status: WallboxPVStatus.PV_BOOST,
          endOfBoost: addSeconds(new Date(), 100).toISOString(),
        },
        tags: [
          { id: '1', name: 'Home' },
          { id: '3', name: 'Private' },
        ],
        configuration: fakeWallboxPowerSettings,
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Offline',
        model: 'KC-P40-32EU0-C633ALP0-LS1R1001B00-CL00', // P40, no wifi, no gsm
        state: RemoteWallboxState.OFFLINE,
        lastStateChange: subHours(new Date(), 2),
        lastSeenConnected: subHours(new Date(), 1),
        connectionStatus: RemoteWallboxConnectionStatus.DISCONNECTED,
        serialNumber: '124',
        firmwareVersion: '1.14.0',
        id: 'w-124',
        requiredFirmwareVersion: '1.15.0',
        updateIndication: RemoteWallboxUpdateState.UPDATE,
        tags: [{ id: '2', name: 'Work' }],
      },
      {
        name: 'Office Indoor',
        state: RemoteWallboxState.ONLINE,
        serialNumber: '125',
        id: 'w-125',
        firmwareVersion: '1.14.0',
        model: 'KC-P40-E*******-***-PV',
        updateIndication: RemoteWallboxUpdateState.LATEST_VERSION,
        tags: [{ id: '2', name: 'Work' }],
        connectionStatus: RemoteWallboxConnectionStatus.DISCONNECTED,
      },
      {
        name: 'Suspended by wallbox',
        state: RemoteWallboxState.SUSPENDED_BY_WALLBOX,
        serialNumber: '126',
        id: 'w-126',
        firmwareVersion: '1.14.0',
        model: 'KC-P40-32EU0-C633ALP0-LS1R1111B00-CL00', // P40, wifi and gsm
        updateIndication: RemoteWallboxUpdateState.UPDATING,
        requiredFirmwareVersion: '1.15.0',
        tags: [{ id: '2', name: 'Work' }],
        configuration: { ...fakeWallboxPowerSettings, pv_enable: 'true' },
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Suspended by car',
        state: RemoteWallboxState.SUSPENDED_BY_CAR,
        serialNumber: '127',
        id: 'w-127',
        firmwareVersion: '1.14.0',
        tags: [{ id: '2', name: 'Work' }],
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Reserved',
        state: RemoteWallboxState.RESERVED,
        serialNumber: '128',
        id: 'w-128',
        firmwareVersion: '1.13.0',
        tags: [{ id: '2', name: 'Work' }],
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Faulted',
        state: RemoteWallboxState.FAULTED,
        serialNumber: '129',
        id: 'w-129',
        firmwareVersion: '1.1.6',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Finished',
        state: RemoteWallboxState.FINISHING,
        serialNumber: '130',
        id: 'w-130',
        firmwareVersion: '1.13.0',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Unavailable',
        state: RemoteWallboxState.UNAVAILABLE,
        serialNumber: '131',
        id: 'w-131',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Preparing',
        state: RemoteWallboxState.PREPARING,
        serialNumber: '132',
        id: 'w-132',
        firmwareVersion: '1.9.0',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
    ]);
  }

  public saveChargepointAvailabilitySettings(
    wallboxId: string,
    availabilities: RemoteWallboxSettingsChargepointAvailabilityDTO[]
  ): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public archiveWallbox(wallboxId: string): Observable<void> {
    return this.delete({ id: wallboxId });
  }

  public startPvBoost(wallboxId: string, durationInSeconds: number | null): Observable<boolean> {
    return this.update({
      id: wallboxId,
      pvStatus: {
        status: WallboxPVStatus.PV_BOOST,
        endOfBoost: durationInSeconds != null ? addSeconds(new Date(), durationInSeconds).toISOString() : undefined,
      },
    }).pipe(map(v => true));
  }

  public stopPvBoost(wallboxId: string): Observable<boolean> {
    return this.update({
      id: wallboxId,
      pvStatus: {
        status: WallboxPVStatus.PV_OPTIMIZING,
        endOfBoost: undefined,
      },
    }).pipe(map(v => true));
  }

  public restart(wallboxId: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public getWallboxSettings(wallboxId: string): Observable<RemoteWallboxSettingsDTO> {
    return this.delayResponse(
      of({
        configuration: {
          [RfidKey.ENABLE_RFID_AUTHORIZATION]: 'true',
          [DiagnosticsKey.MAX_DAYS_OF_LOGS]: '2',
          api_alias: 'Test alias',
          [LmgmtKey.PV_ENABLE]: 'false',
          [LmgmtKey.PV_DELAY]: '120',
          [LmgmtKey.PV_THRESHOLD_EXPORT]: '1000',
          [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'false',
          [DisplayTextKey.LANGUAGE]: 'de',
          [NetworkKey.ENABLE_SSH_SERVER]: 'false',
        },
        displayText: [
          {
            lang: 'en',
            key: 'display_text_card_please',
            value: 'Card please',
            minTime: 1,
            maxTime: 3,
            frequency: 0,
          },
          {
            lang: 'de',
            key: 'display_text_card_please',
            value: 'Karte bitte',
            minTime: 1,
            maxTime: 3,
            frequency: 0,
          },
        ],
        chargingNetworkChargepoints: [
          {
            aliasName: 'Test 1',
            availability: RemoteWallboxState.AVAILABLE,
            serialNumber: '123',
            connectorId: 1,
            maxPhases: MaxPhases.THREE,
            phaseUsed: PhaseUsed.L1_L2_L3,
            failsafeCurrent: UnitConverter.ampereToMilliAmpere(32),
            id: '1',
          },
          {
            aliasName: 'Test 2',
            availability: RemoteWallboxState.UNAVAILABLE,
            serialNumber: '456',
            connectorId: 2,
            maxPhases: MaxPhases.ONE,
            phaseUsed: PhaseUsed.L1,
            failsafeCurrent: UnitConverter.ampereToMilliAmpere(8),
            id: '2',
          },
        ],
      })
    );
  }

  public saveWallboxSettings(
    wallboxId: string,
    settings: RemoteWallboxSettingsDTO
  ): Observable<RemoteWallboxSettingsDTO> {
    return this.delayResponse(of(settings));
  }

  public getFirmwareUpdateDetails(
    wallboxId: string,
    language?: string
  ): Observable<RemoteWallboxFirmwareUpdateInfoDTO> {
    return this.delayResponse(
      of({
        updateId: '1',
        updateIndication: RemoteWallboxUpdateState.UPDATE,
        currentVersion: '1.15.0',
        nextAvailableVersion: { id: '1', major: 1, minor: 1, patch: 0 },
        description: 'test de',
      })
    );
  }

  public changeMaxAvailableCurrent(wallboxId: string, maxAvailableCurrent: number): Observable<void> {
    return this.update({
      id: wallboxId,
      configuration: {
        ...fakeWallboxPowerSettings,
        max_available_current: String(maxAvailableCurrent),
      },
    });
  }

  protected override applyFilter(items: RemoteWallbox[], f: Filter): RemoteWallbox[] {
    if (f.fieldName === 'state') {
      const filterStates = f.value as RemoteWallboxState[];
      return items.filter(wallbox => (wallbox.state ? filterStates.includes(wallbox.state) : false));
    }

    if (f.fieldName === 'tags') {
      const filterTagNames = f.value as string[];
      return items.filter(wallbox =>
        filterTagNames.every(filterTagName => wallbox.tags?.some(t => t.name === filterTagName))
      );
    }

    return super.applyFilter(items, f);
  }

  public saveName(wallboxId: string, name: string): Observable<void> {
    return this.update({ id: wallboxId, name });
  }
}
