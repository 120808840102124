import { MaxPhases, PhaseUsed } from '@kw-shared/common';
import { BaseModel, RemoteWallboxState } from '../../../../../models';

export interface RemoteWallboxSettingsDTO {
  configuration: RemoteWallboxSettingsConfiguration;
  displayText?: RemoteWallboxSettingsDisplayTexts[];
  chargingNetworkChargepoints?: RemoteWallboxSettingsChargepoint[];
}

export interface RemoteWallboxSettingsDisplayTexts {
  lang: string;
  key: string;
  value?: string;
  minTime?: number;
  maxTime?: number;
  frequency?: number;
}

export interface RemoteWallboxSettingsConfiguration {
  [key: string]: string | string[] | undefined;
}

export interface RemoteWallboxSettingsChargepointAvailabilityDTO {
  connectorId: number;
  serialNumber: string;
  availabilityType: 'Inoperative' | 'Operative';
}

export interface RemoteWallboxSettingsChargepoint extends BaseModel {
  connectorId: number;
  aliasName?: string;
  maxPhases?: MaxPhases;
  phaseUsed?: PhaseUsed;
  availability?: RemoteWallboxState;
  serialNumber: string;
  failsafeCurrent?: number;
}

export enum ExportStatus {
  LOADING = 'LOADING',
  FINISHED = 'FINISHED',
  NOT_STARTED = 'NOT_STARTED',
}
