export abstract class WallboxNameRegex {
  public static readonly VALID_NAME = /^(?!.*[=+\-\f\n\r\t\x0B<>&'`´])[a-zA-Z][a-zA-Z0-9_]*$/;
  public static readonly VALID_ALIAS = /^(?!.*[=+\-\f\n\r\t\x0B<>&'`´])[a-zA-Z][a-zA-Z0-9_]{0,11}$/;
}

export abstract class WallboxBlePinRegex {
  public static readonly NO_LEADING_ZERO = /^[1-9][0-9]*$/;
}

export abstract class WallboxProductCodeRegex {
  // https://reqpool.atlassian.net/wiki/spaces/KEBA/pages/169541639/Product+Code+P40+Wallbox
  public static readonly IS_P40 = /KC-P40-.*/;
  public static readonly P40_HAS_GSM_MODULE = /KC-P40-.....................1.*/;
  public static readonly P40_HAS_WIFI_MODULE = /KC-P40-....................1.*/;
  public static readonly P40_16A = /KC-P40-16.*/;
  public static readonly P40_32A = /KC-P40-32.*/;

  // https://reqpool.atlassian.net/wiki/spaces/KEBA/pages/27230359/Product+code+P30+Wallbox
  public static readonly IS_P30 = /KC-P30-.*/;
  public static readonly P30_HAS_GSM_MODULE = /KC-P30-......[CEGHS].-.*/;
  public static readonly P30_HAS_WIFI_MODULE = /KC-P30-......[BCESU].-.*/;
  public static readonly P30_DOESNT_SUPPORT_CHARGEPOINT_PARAMETERS = /KC-P30-.*-?(CC|PV).?.?$/;
}
